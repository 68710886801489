import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/ContactActivity/:token/:parent",
    name: "ContactActivity",
    component: () => import("../views/ContactActivity.vue"),
    props: true,
  },
  {
    path: "/ContactActivity/:token/:parent/:preload",
    name: "ContactActivity",
    component: () => import("../views/ContactActivity.vue"),
    props: true,
  },
  {
    path: "/ContactList/:token/:daterange/:user/:contactsWithProperties/:cdaCalls",
    name: "ContactList",
    component: () => import("../views/ContactList.vue"),
    props: true,
  },
  {
    path: "/ContactList/:token/:daterange/:user/:contactsWithProperties/:cdaCalls/:cdaBatch",
    name: "ContactList",
    component: () => import("../views/ContactList.vue"),
    props: true,
  },
  {
    path: "/ToDoList/:token",
    name: "ToDoList",
    component: () => import("../views/ToDoList.vue"),
    props: true,
  },
  {
    path: "/PropertyMerge/:token",
    name: "PropertyMerge",
    component: () => import("../views/PropertyMerge.vue"),
    props: true,
  },
  {
    path: "/ClientMerge/:token",
    name: "ClientMerge",
    component: () => import("../views/ClientMerge.vue"),
    props: true,
  },
  {
    path: "/WeeklyMinutes/:token",
    name: "WeeklyMinutes",
    component: () => import("../views/WeeklyMinutes.vue"),
    props: true,
  },
  {
    path: "/PropertyFollowUpReport/:token",
    name: "PropertyFollowUpReport",
    component: () => import("../views/PropertyFollowUpReport.vue"),
    props: true,
  },
  {
    path: "/MailingLists/:token",
    name: "MailingLists",
    component: () => import("../views/MailingLists.vue"),
    props: true,
  },
  {
    path: "/CDAActivitySummary/:token",
    name: "CDAActivitySummary",
    component: () => import("../views/CDAActivitySummary.vue"),
    props: true,
  },  {
    path: "/CurrentContacts/:token",
    name: "CurrentContacts",
    component: () => import("../views/CurrentContacts.vue"),
    props: true,
  },  {
    path: "/CurrentContacts/:token/:user",
    name: "CurrentContacts",
    component: () => import("../views/CurrentContacts.vue"),
    props: true,
  },
  {
    path: "/ContactActivitySummary/:token",
    name: "ContactActivitySummary",
    component: () => import("../views/ContactActivitySummary.vue"),
    props: true,
  },
  {
    path: "/EditCouncils/:token",
    name: "EditCouncils",
    component: () => import("../views/EditCouncils.vue"),
    props: true,
  },
  {
    path: "/TestPage/:token",
    name: "TestPage",
    component: () => import("../views/TestPage.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
