import Vue from "vue";
Vue.config.devtools = true

import App from "./App.vue";

//vuex (state management)
import store from "./store";

//vue router
import router from "./router";

//bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

//wijmo
import "@grapecity/wijmo.styles/wijmo.css";
import '@grapecity/wijmo.vue2.nav';
import "@grapecity/wijmo.vue2.grid";
import "@grapecity/wijmo.vue2.input";
import * as wjCore from "@grapecity/wijmo";

import "./assets/pho.css";

wjCore.setLicenseKey(
  "datum.bawdens.com.au|phovue.bawdens.com.au|apipho.propertyhubonline.com|johnhill.propertyhubonline.com|datumpr.bawdens.com.au|pr.propertyhubonline.com|new.propertyhubonline.com|my.propertyhubonline.com|dev.bawdens.com.au|dev.propertyhubonline.com|pho.johnhill.com.au,319379893443286#B0nvBmLz9WZkdXYi9SZ5Z7boBHL5FmLt36YuMnblR6dhJmLtVHdhRmI0IyctRkIsICZ4xEI9RHUgMXZ4FWaj36czFEI9VGb6F6dTJiOiEmTDJCLiYDOyMDN4MTO8kzNzkTMzIiOiQWSiwSfiMjdwIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7BHRwMzbypVbINVRTRnT7BFbktWS5UzdJVWSiVjdzFzVwlWbaRFRityQk94VkJVcHF7anp5R8E4KBNDR5ImbPR6Zjt4T8BDMnhjcIFVWwJ7ZRRlQF3yTIlzR6pEWplTW79mS6FjNUpnZYRWRLVVUItEbvQTYYZHcORkdUFEWRdnSyhEZ5JkQZdGUFlzRq9mMhZkcEJHbUFDNWJ4QmllZroGdQdlaRVGbqJ5d0FTRrdlQBtyaHdzdPVGTvR6LwEVTG9GTuFUTqhjNjREVsVlaQJ5SahnNyoleUN6NWB5KPRFU7h5QTpkVUVVRQF5d0FES9AlS5kURCpGeiRVONBXYRl4VrYUQtBVbzJUZNJWdDNDTYdkQotWNqh6VHd5LllTRpd5NRpVVStCa9QlTEJHMol6asRWc4UDNGt4UtpHe6MXV6Y4QJFEVwkkNtZ4SxVjSpJ7QzFkZiojITJCLikzNEZTOCNkI0ICSiwSM4UTM7MzM5gTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIwEjNxcDMgYDMxEzMyAjMiojI4J7QiwiI5FmLt36YuwGbphmbo3mau2GawxSbvNmLl9Was96biVHa9RnclB7byBnL6VGZsUXYu46bj9ycuVGZ7FmYuYXZkxSbvNmLl9Was96biVHa9RnclB7byBnL95GLt36YuUmbpxmbvJWdolHdyVGcvJHcucXZuxSbvNmLl9Was96biVHa9RnclB7byBnLyBHL5FmLt36YuMnblR6dhJmLyBXb5RXYkxSbvNmLl9Was96biVHa9RnclB7byBnLsxWao9GavpGLt36YuUmbpxmbvJWdolHdyVGcvJHcu2GawlGchxS6hDS"
);

//wjCore.setLicenseKey(
//   "phovue.johnhill.com.au,319379893443286#B0yWXmpjIyNHZisnOiwmbBJye0ICRiwiI34zZEJ5brYDakJlUYNHS7pmbat4asR4arN6ZJR4L6oXQQJEbltCMvpVS8cERO3kMCVHVEZ4dzYTV4YETEtiSRhmZVd5RWBDeOVWesl7UwVFORtEUr56Z8AVSWZEd4hjaDZETVdWc4kDb7MneXNmU9Q7bYhTaGdFb5ZDe6JlZ94EbsVVSzg4SYdFapdUS4MWejVnN9QzcJdHNjNjdRRGS4ITSRdFWp5GMhBTZoJ4RvlzS5NUQT9mbj3WO6xmeDFldTdVeNlDZxFmZ9N6NmF7cIlGZ5hnUXdjNX9GdTpWVroFOJdzT4EWME96dwcTduNDV7FDOGt4KH9mSMNFOE9GNy4GW7V4MKlnYrp5UihlWKVzQqB7VHpkT7lVe4YnT8JEOB3GbZp6MwJWaM3UNRZzTVJlZzokNydjYW3CMmlmVWNzNPdkZWJGS4FTO9hmM4ZzY45Wa9VUMyFkI0IyUiwiI5ITQ7MkNGZjI0ICSiwCO4cjN4gzM6gTM0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiIyMDO5ITMgkDMxEzMyAjMiojI4J7QiwiI5FmLt36YuwGbphmbo3mauUWd63GawJiOiMXbEJCLiQGdMBSe4BFIzVGdhl6YvN7cBBSelxmdhd7UiojIh94QiwiI6gjMzQDNzkDO9czM9EzMiojIklkIs4nIzYHMyAjMiojIyVmdizSWz"
//);



//google maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBMy9SB7sLswdfIHixggiJeonfMJc5k3AU",
    libraries: "places",
  },
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
