//no backslash at the end of the url

//FOR LOCAL
//export const API = "https://localhost:44374";
//export const REST = "http://localhost:50783";

//FOR DATUMPR
export const API = "https://apipho.bawdens.com.au";
export const REST = "https://datumpr.bawdens.com.au";

//FOR JohnHill
//export const API = "https://phoapi.johnhill.com.au";
//export const REST = "https://pho.johnhill.com.au";


//FOR DEV
//export const API = "https://apipho.propertyhubonline.com";
